import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import merge from "lodash/merge";

declare type NoNameFUnctionReturnVoid = () => void;

type State = {
  loading: boolean;
  alertDialogOpen: boolean;
  alertDialogMessage: string;
  alertDialogType: string;
  confirmDialogOpen: boolean;
  confirmDialogMessage: string;
  confirmFunction: NoNameFUnctionReturnVoid | null;
  errorMessage: string;
  environment: string;
};

const initialState: State = {
  loading: false,
  alertDialogOpen: false,
  alertDialogMessage: "",
  alertDialogType: "success",
  confirmDialogOpen: false,
  confirmDialogMessage: "",
  confirmFunction: null,
  errorMessage: "",
  environment: "development",
};

const slice = createSlice({
  name: "information",
  initialState,
  reducers: {
    initializeState: (state) => {
      return Object.assign({}, initialState);
    },
    setState: (state, action: PayloadAction<Partial<State>>) => {
      Object.keys(action.payload).map((key) => {
        merge(state, { [key]: action.payload[key] });
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase("information/setState", (state, action) => {
      state.errorMessage = "";
    });
  },
});

export default slice.reducer;

export const { initializeState, setState } = slice.actions;
